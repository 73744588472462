import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Sexos } from 'app/shared/models/aplicacao/sexos';
import { Observable } from 'rxjs';
import { EstadosCivis } from 'app/shared/models/aplicacao/estados-civis';
import {catchError, map, tap} from 'rxjs/operators';
import { Paises } from 'app/shared/models/aplicacao/paises';
import { Municipios } from 'app/shared/models/aplicacao/municipios';
import { Provincias } from 'app/shared/models/aplicacao/provincias';
import { CategoriasContactos } from 'app/shared/models/aplicacao/categoriascontactos';
import { TiposConctatos } from 'app/shared/models/aplicacao/tiposcontactos';
import { Aplicacao } from 'app/shared/models/aplicacao/aplicacao';
import { Moedas } from 'app/shared/models/aplicacao/moedas';
import { Idiomas } from 'app/shared/models/aplicacao/idiomas';
import { Menu } from 'app/shared/models/aplicacao/menu';
import { environment } from 'environments/environment';
import { OpccaoInsercao } from 'app/shared/models/aplicacao/opccaoInsercao';
import { Permissoes } from 'app/shared/models/aplicacao/permissoes';
import { PermissoesTipos } from 'app/shared/models//aplicacao/permissoes-tipos';
import { Unidades } from 'app/shared/models/aplicacao/unidades';
import { EmailsEnvio } from 'app/shared/models/aplicacao/emailsenvio';
import { ProeficienciaIdiomas } from 'app/shared/models/aplicacao/proeficienciaidiomas';
import { EmailsLogs } from 'app/shared/models/aplicacao/emailslogs';
import { Perfis } from 'app/shared/models/aplicacao/perfis';
import { Zonas } from 'app/shared/models/aplicacao/zonas';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AplicacaoService {
  baseUrlAplicacao: string;

  constructor(private http: HttpClient) {
    this.baseUrlAplicacao = `${environment.apiAplicacao}`;
  }

  //#region  Área feita antes de 01-04-2021
  getLogotipoApp(): Observable<any> {
    return this.http.get(this.baseUrlAplicacao + 'aplicacao/logotipo', httpOptions);
  }

  getAplicacao(): Observable<Aplicacao> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao', httpOptions)
        .pipe(catchError(null));
  }

  getMenus(): Observable<Menu[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/menus/1', httpOptions)
        .pipe(catchError(null));
  }

  getMoedas(): Observable<Moedas[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/moedas', httpOptions)
        .pipe(catchError(null));
  }

  getTiposEmpregos(): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/TiposEmprego', httpOptions)
        .pipe();
  }

  getIdiomas(): Observable<Idiomas[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/idiomas', httpOptions)
        .pipe(catchError(null));

  }

  getTodosIdiomas(): Observable<Idiomas[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/todosIdiomas', httpOptions)
        .pipe(catchError(null));
  }
  getIdiomaAplicacao(): Observable<Idiomas[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/idiomas/utilizadores', httpOptions)
        .pipe(catchError(null));
  }

  getProeficiencia(): Observable<ProeficienciaIdiomas[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/proeficienciaidiomas', httpOptions)
        .pipe(catchError(null));
  }

  gravarPermissoes(modelo: any) {
    return this.http
        .post(this.baseUrlAplicacao + 'permissoes', modelo, httpOptions)
        .pipe(catchError(null));
  }

  getPermissoes(): Observable<Permissoes[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes', httpOptions)
        .pipe(catchError(null));
  }

  getPermissoesTipos(): Observable<PermissoesTipos[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/tipos', httpOptions)
        .pipe(catchError(null));
  }

  // ECARVALHO - Retirar
  getPermissoesAplicacao(idUtilizador: number): Observable<Permissoes[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/' + idUtilizador, httpOptions)
        .pipe(catchError(null));
  }

  getPermissoesUtilizador(idUtilizador: number): Observable<any> {
    return this.http
        .get(
            this.baseUrlAplicacao + 'permissoes/utilizador/' + idUtilizador,
            httpOptions
        )
        .pipe(catchError(null));
  }

  getPaises(): Observable<Paises[]> {
    return this.http
      .get(this.baseUrlAplicacao + 'aplicacao/paises', httpOptions)
      .pipe(catchError(null));
  }
  getEstados(): Observable<any> {
    return this.http
      .get(this.baseUrlAplicacao + 'aplicacao/estados', httpOptions)
      .pipe(catchError(null));
  }

  getProvincias(codPais: string): Observable<Provincias[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/provincias/' + codPais, httpOptions)
        .pipe(catchError(null));
  }

  getMunicipios(codPais: string, idProvincia: number): Observable<Municipios[]> {
    return this.http
        .get(
            this.baseUrlAplicacao + 'aplicacao/municipios/' + codPais + '/' + idProvincia,
            httpOptions
        )
        .pipe(catchError(null));
  }

  getZonas(idMunicipio: number): Observable<Zonas[]> {
    return this.http
        .get(
            this.baseUrlAplicacao + 'aplicacao/zonas/'+idMunicipio,
            httpOptions
        )
        .pipe(catchError(null));
  }

  getSexos(): Observable<Sexos[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/sexos', httpOptions)
        .pipe(catchError(null));
  }

  getEstadosCivis(): Observable<EstadosCivis[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/estadoscivis', httpOptions)
        .pipe(catchError(null));
  }

  getCategoriasContactos(): Observable<CategoriasContactos[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/categoriascontactos', httpOptions)
        .pipe(catchError(null));
  }

  getTiposContactos(): Observable<TiposConctatos[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/tiposcontactos', httpOptions)
        .pipe(catchError(null));
  }

  getOpcoesInsercao(): Observable<OpccaoInsercao[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/opcoesinsercao', httpOptions)
        .pipe(catchError(null));
  }

  getUnidades(idTipo: number): Observable<Unidades[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/unidadesmedidas/' + idTipo, httpOptions)
        .pipe(catchError(null));
  }

  getEmails(
      activo: boolean,
      disponivelEmails: boolean
  ): Observable<EmailsEnvio[]> {
    return this.http
        .get(
            this.baseUrlAplicacao +
            'aplicacao/emailsEnvio/' +
            activo +
            '/' +
            disponivelEmails,
            httpOptions
        )
        .pipe(catchError(null));
  }

  getLogs(): Observable<EmailsLogs[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/emaillogs/', httpOptions)
        .pipe(catchError(null));
  }

  getLog(id: number): Observable<EmailsLogs> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/emaillog/' + id, httpOptions)
        .pipe(catchError(null));
  }

  removerLog(modelo: any) {
    return this.http
        .post(this.baseUrlAplicacao + 'aplicacao/emaillog/remover', modelo, httpOptions)
        .pipe(catchError(null));
  }

  getEmail(idEmail: number): Observable<EmailsEnvio> {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/emailenvio/' + idEmail, httpOptions)
        .pipe(catchError(null));
  }

  salvarEmail(modelo: any) {
    return this.http
        .post(this.baseUrlAplicacao + 'aplicacao/emailenvio/salvar/', modelo, httpOptions)
        .pipe(catchError(null));
  }

  enviarEmail(modelo: any) {
    return this.http
        .post(this.baseUrlAplicacao + 'aplicacao/enviaremail', modelo, httpOptions)
        .pipe(catchError(null));
  }

  enviarEmailTeste(modelo: any) {
    return this.http
        .post(this.baseUrlAplicacao + 'aplicacao/testaremail', modelo, httpOptions)
        .pipe(catchError(null));
  }

  editarAplicacao(modelo: any): Observable<any>  {
    return this.http
        .post(this.baseUrlAplicacao + 'aplicacao/editarAplicacao/', modelo, httpOptions)
        .pipe();
  }

  getAppIcones() {
    return this.http
        .get(this.baseUrlAplicacao + 'aplicacao/icones', httpOptions)
        .pipe(catchError(null));
  }
//#endregion
//#region  Ária de Permissões do Perfil

// gravarPermissoes(modelo: any) {
//   return this.http
//     .post(this.baseUrlAplicacao + 'permissoes', modelo, httpOptions)
//     .pipe(catchError(null));
// }

  getPerfis(): Observable<Perfis[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/perfil', httpOptions)
        .pipe(catchError(null));
  }
  getPerfil(id: number): Observable<Perfis> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/perfil/' + id, httpOptions)
        .pipe(catchError(null));
  }
  getPerfisPermissoes(): Observable<any[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/perfil/permissoes', httpOptions)
        .pipe(catchError(null));
  }
  getPerfilPermissoes(id: number): Observable<any[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/perfil/permissoes/' + id, httpOptions)
        .pipe(catchError(null));
  }
  getPerfilUtilizador(id: number): Observable<any> {
    return this.http
        .get(this.baseUrlAplicacao + 'permissoes/perfil/utilizador/' + id, httpOptions)
        .pipe(catchError(null));
  }
  gravarPerfil(modelo: any): Observable<Perfis> {
    return this.http
        .post(this.baseUrlAplicacao + 'permissoes/perfil', modelo, httpOptions)
        .pipe(catchError(null));
  }
  eliminarPerfil(idPerfil: number): Observable<Perfis> {
    return this.http
        .post(this.baseUrlAplicacao + 'permissoes/perfil/eliminar', idPerfil, httpOptions)
        .pipe(catchError(null));
  }
  eliminarPermissaoPerfil(idPerfilPermissao: number): Observable<Perfis> {
    return this.http
        .post(this.baseUrlAplicacao + 'permissoes/perfil/permissao/eliminar', idPerfilPermissao, httpOptions)
        .pipe(catchError(null));
  }
  gravarPerfilPermissoes(modelo: any) {
    return this.http
        .post(this.baseUrlAplicacao + 'permissoes/perfil/gravarPermissoes', modelo, httpOptions)
        .pipe(catchError(null));
  }
  gravarPerfilUtilizador(modelo: any) {
    return this.http
        .post(this.baseUrlAplicacao + 'permissoes/perfil/utilizador', modelo, httpOptions)
        .pipe(catchError(null));
  }
  getmetodosPagRec(): Observable<any[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'clientes/comercial/metodosPagRec', httpOptions)
        .pipe(catchError(null));
  }
  getCondicoesPagamento(): Observable<any[]> {
    return this.http
        .get(this.baseUrlAplicacao + 'clientes/comercial/condicoespagamento', httpOptions)
        .pipe(catchError(null));
  }
//#endregion
}
