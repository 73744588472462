import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'tipoFicheiro'
})
export class TipoFicheiroPipe implements PipeTransform {
constructor(protected sanitizer: DomSanitizer) {
}
 public transform(valor: any): any {
     let extensaoFicheiro = '';
     let partesLinkFicheiro;
    if (valor) {
        partesLinkFicheiro = valor.split('.');
       if (partesLinkFicheiro?.length > 0) {
           extensaoFicheiro = partesLinkFicheiro[partesLinkFicheiro.length - 1];
       }

        switch (extensaoFicheiro) {
            case 'pdf': return  'assets/images/icons/pdf-icon.svg';
            case 'msword': return  'assets/images/file-types/005-documents.svg';
            case 'vnd.openxmlformats-officedocument.wordprocessingml.document': return  'assets/images/icons/word-icon.svg';
            // Ficheiro Excel
            case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet': return  'assets/images/icons/xcel-icon.svg';
            // Ficheiro PowerPoint
            case 'vnd.openxmlformats-officedocument.presentationml.presentation': return  'assets/images/icons/powerpoint-icon.svg';
            case 'jpeg': return valor;
            case 'jpg': return valor;
            case 'png': return valor;
            case 'svg': return valor;
            case 'webp': return valor;
            default: return 'assets/images/icons/default_ficheiro.svg';
        }

    }

  }

}
