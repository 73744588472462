import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'linkYouTube'
})
export class LinkYoutubePipe implements PipeTransform {
constructor(protected sanitizer: DomSanitizer) {
}
 public transform(link: any): any {
     let idLinkVideo = '';
     let partesLink;
    if (link && link.includes('www.youtube.com')) {
        partesLink = link.split('=');
       if (partesLink?.length > 0) {
           idLinkVideo = partesLink[1];
           return 'https://i.ytimg.com/vi/' + idLinkVideo + '/hqdefault.jpg?';
       }
    }

  }

}
