import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CmsGaleriasService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.cmsUrl}`+'galerias/';
  }
  galerias(codIdioma:string): Observable<any> {
    return this.http.get(this.baseUrl + codIdioma).pipe();
  }
  galeria(idGaleria: number,codIdioma:string): Observable<any> {
    return this.http.get(this.baseUrl + idGaleria+'/'+codIdioma).pipe();
  }
  galeriaAlbuns(idGaleria: number,codIdioma:string): Observable<any> {
    return this.http.get(this.baseUrl+'albuns/' + idGaleria+'/'+codIdioma).pipe();
  }
  removerGalerua(idGaleria: number): Observable<any> {
    return this.http.delete(this.baseUrl + "eliminar/" + idGaleria).pipe();
  }
  gravarGaleria(modelo: any): Observable<any> {
    return this.http.post(this.baseUrl, modelo).pipe();
  }
}
