import { environment } from 'environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import {LocalStoreService} from '../local-store.service';
import {Utilizadores} from '../../models/aplicacao/utilizadores';
import { BehaviorSubject, Observable, observable } from 'rxjs';
import { Permissoes } from 'app/shared/models/aplicacao/permissoes';
import { PermissoesDataService } from './data/permissoes-data.service';


@Injectable({
  providedIn: 'root',
})
export class LoginService {
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  utilizadorDto:any;

  permissoesUtilizador: Permissoes[]=[]

  mudarPassord: any;
  baseUrl: string;
  token: string;
  isAuthenticated: boolean;
  user: Utilizadores;
  user$ = (new BehaviorSubject<Utilizadores>(null));
  signingIn: boolean;
  return: string;
  APP_USER = '_utilizador';


  constructor(private http: HttpClient,  private localSore: LocalStoreService,  private permissoesDataService: PermissoesDataService) {
    this.baseUrl = `${environment.apiAplicacao}`;
  }

  login(model: any): Observable<any> {
    return this.http.post(this.baseUrl + 'aplicacao/login', model).pipe(
      map(
        (response: any) => {
          const user = response.objecto;
          if (user?.token) {
            localStorage.setItem('token', user.token);
            this.decodedToken = this.jwtHelper.decodeToken(user.token);
            this.utilizadorDto = user.utilizador;
            localStorage.setItem('@u53r1d$', this.decodedToken.nameid);
            this.mudarPassord = user.mudarPass;
            this.permissoesDataService.setListaPermissoes(user.permissoes?.objecto);
            this.permissoesUtilizador = user.permissoes?.objecto;
            localStorage.setItem('permissoesUtilizador', JSON.stringify(this.permissoesUtilizador));
          }
        },
        (error) => {
          console.log('Error', error);
        }
      )
    );
  }

  getPermissoes():any{
    if (localStorage.getItem('permissoesUtilizador') != null) {
      return JSON.parse(localStorage.getItem('permissoesUtilizador'));
    }
    return [];
  }

  loggedIn() {
    if (localStorage.getItem('token') != null) {
      const token = localStorage.getItem('token');
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }

  setUserAndToken(token: string, user: Utilizadores, isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.user = user;
    this.user$.next(user);
    this.localSore.setItem(this.APP_USER, user);
  }
}
